import axios from "axios";
import config from "core/config";

const apiURL = config.API_BASE_URL;
const v1ApiURL = config.API_BASE_URL + "v1/";

function makeApiRequest(
    url,
    method = "get",
    headers = {},
    data = {},
    timeout = 0
) {
    const config = {
        method,
        url,
        headers,
        data,
        maxBodyLength: Infinity,
        timeout,
    };

    return axios
        .request(config)
        .then((response) => response.data)
        .catch((error) => {
            console.error(error);
            throw error;
        });
}

export const login = async (email, password, is_subscriber) =>
    await makeApiRequest(
        apiURL + "auth/login/",
        "post",
        {},
        { email, password, is_subscriber }
    );

export const register = async (full_name, email, password) =>
    await makeApiRequest(
        apiURL + "auth/register/",
        "post",
        {},
        { full_name, email, password }
    );

export const activateAccount = async (uid, token) =>
    await makeApiRequest(
        apiURL + "auth/activate/" + uid + "/" + token,
        "get",
        {}
    );

export const verifyToken = async (token) =>
    await makeApiRequest(apiURL + "auth/token-verify/", "post", {
        Authorization: "Token " + token,
        "Content-Type": "application/json",
    });
export const resetPassword = async (email) =>
    await makeApiRequest(apiURL + "auth/reset-password/", "post", {}, { email });
export const resetPasswordConfirm = async (uid, token, password) =>
    await makeApiRequest(
        apiURL + "auth/reset-password/confirm/",
        "post",
        {},
        { uid, token, new_password: password }
    );

export const allChatHistories = async (token) =>
    await makeApiRequest(apiURL + "conversations/", "get", {
        Authorization: "Token " + token,
        "Content-Type": "application/json",
    });
export const conversation = async (
    token,
    id = null,
    userMessage = null,
    pid = null,
    sid = null,
    mid = null,
    limit = 50,
    offset = 0
) => {
    const url = apiURL + "conversation/";
    const headers = {
        Authorization: "Token " + token,
        "Content-Type": "application/json",
    };
    if (id) {
        const queryParams = `limit=${limit}&offset=${offset}`;
        return await makeApiRequest(`${url}${id}?${queryParams}`, "get", headers);
    } else {
        const data = {
            message: userMessage,
            parent_id: pid,
            session_id: sid,
            message_id: mid,
        };
        return await makeApiRequest(
            v1ApiURL + "conversation/",
            "post",
            headers,
            data
        );
    }
};

export const messageFeedback = async (token, feedbackData) =>
    await makeApiRequest(
        v1ApiURL + "conversation/message_feedback",
        "post",
        {
            Authorization: "Token " + token,
            "Content-Type": "application/json",
        },
        feedbackData
    );

export const allSettings = async () =>
    await makeApiRequest(v1ApiURL + "settings/", "get", {
        "Content-Type": "application/json",
    });

export const userSettings = async (token, method = "get", data = {}) =>
    await makeApiRequest(
        v1ApiURL + "me/",
        method,
        {
            Authorization: "Token " + token,
            "Content-Type": "application/json",
        },
        data
    );
