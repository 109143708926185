import { imageAssets } from 'components/imageAssets';

const UserGuide = () => {
    return (
        <>
            <div className="container">
            <h4 className="text-3572EF mb-3 mt-2">Step-by-Step Guide to Using Bevan.ai</h4>
                   <p className="text-dark"><strong>Welcome to Bevan.ai!</strong> As we continue to train and refine our AI model, your interactions are crucial. This guide will help you navigate the tool effectively. </p>

                   <div className="py-2 pt-2">
                    <h5 className="text-3572EF pb-2"><i class="fa-solid fa-angles-right text-md"></i> Step 1: Login to <a className="text-decoration-underline text-primary ml-1" href="https://bevan.ai/" rel="noopener noreferrer" target="_blank">Bevan.ai</a></h5>
                    <ul className="mx-4">
                        <li>Check the box if you subscribe to Digital Health Intelligence or leave blank if not.</li>
                    </ul>
                    <div className="text-center"><img src={imageAssets.step1} alt="Login to Bevan.ai" className="w-30" /></div>
                   </div>

                   <div className="py-2">
                    <h5 className="text-3572EF pb-2"><i class="fa-solid fa-angles-right text-md"></i> Step 2: Enter Your Question</h5>
                    <h6 className="px-4">Where to Ask:</h6>
                    <ul className="mx-4">
                            <li>Locate the query input box on the main interface. </li>
                            <li>Type your question into the box. </li>
                    </ul>
                    <div className="text-center"><img src={imageAssets.step2} alt="Enter Your Question" className="w-80 pt-2 pb-4" /></div>
                    <p className="text-dark px-4 text-center"><strong>Tip:</strong> Be as specific as possible. Detailed questions lead to more accurate and helpful responses.</p>
                   </div>

                   <div className="py-2">
                    <h5 className="text-3572EF pb-2"><i class="fa-solid fa-angles-right text-md"></i> Step 3: Review the Response </h5>
                    <h6 className="px-4">What You'll See:</h6>
                    <ul className="mx-4">
                            <li>Bevan.ai will generate a response based on your query. The answer will typically be a summary or a concise reply.</li>
                    </ul>

                    <h6 className="px-4">Response Format: </h6>
                    <ul className="mx-4">
                            <li>Responses are usually short and may not cover every aspect of a broad question. This is particularly true for queries about widespread initiatives or national-level data. </li>
                    </ul>
                    <div className="text-center"><img src={imageAssets.step3} alt="Review the Response" className="w-95 pt-2 pb-4 text-center" /></div>
                    <p className="text-dark px-4"><strong>Example:</strong> If you ask, "which trusts are leading virtual wards?" you might receive a summary of a few key projects rather than a comprehensive list. Refining your question can help narrow down the responses to more specific details. </p>
                   </div>


                   <div className="py-2">
                    <h5 className="text-3572EF pb-2"><i class="fa-solid fa-angles-right text-md"></i> Step 4: Rate the Response </h5>
                    <h6 className="px-4">Provide Feedback: </h6>
                    <ul className="mx-4">
                            <li>Use the thumbs up/down icon located below the response to indicate whether the answer was helpful, or the partial response icon if it was somewhat helpful.</li>
                    </ul>

                    <h6 className="px-4">Why Rate?</h6>
                    <ul className="mx-4">
                            <li>Your feedback helps us improve the model. A thumbs up signifies a useful answer, while a thumbs down indicates areas for improvement. </li>
                    </ul>
                    <div className="text-center"><img src={imageAssets.step4} alt="Review the Response" className="w-50 pt-2 pb-4" /></div>
                   </div>

                   <div className="py-2">
                    <h5 className="text-3572EF pb-2"><i class="fa-solid fa-angles-right text-md"></i> Step 5: Refine or Regenerate the Response </h5>
                    <h6 className="px-4">Not Satisfied? </h6>
                    <ul className="mx-4">
                            <li>Use the "Regenerate Response" button if the initial answer doesn't fully meet your needs. As Bevan.ai learns, it may take a couple of attempts to get the most accurate and useful response. </li>
                    </ul>

                    <h6 className="px-4">Try Again:</h6>
                    <ul className="mx-4">
                            <li>Sometimes, a slight tweak in phrasing or a second attempt can yield a better answer. Don’t hesitate to refine your question or try again.</li>
                    </ul>
                   </div>

                   <div className="py-2">
                    <h4 className="text-3572EF mb-4 mt-3">Tips and Best Practices  </h4>
                    <h5 className="text-dark px-4">1. Be Specific: </h5>
                    <ul className="mx-4">
                        <li><strong>Focus: </strong>Tailor your question to a single aspect, organisation, or policy to obtain the most relevant and precise information.</li>
                        <li><strong>Scope: </strong>You can inquire about:
                            <ul>
                                <li>Specific developments in a particular NHS organisation.</li>
                                <li>Trends or initiatives in a particular region or on a national level.</li>
                                <li>Specific policy initiatives, technology categories, or suppliers within the NHS.</li>
                            </ul>
                         </li>
                         <li><strong>Examples: </strong>
                            <ul>
                                <li>Instead of asking, "What are the NHS' digital strategies?" ask, "What are the key digital strategies for NHS Trusts in London?" </li>
                                <li>Alternatively, you can reference a specific organisation or document: "What are the key priorities at Manchester University NHS Foundation Trust based on their digital strategy report?</li>
                            </ul>
                         </li>                                                      
                    </ul>
                    <h5 className="text-dark px-4">Use Clear Language: </h5>
                    <ul className="mx-4">
                        <li>Trust abbreviations like GSST (Guy's and St Thomas' NHS Foundation Trust) or BHRUT (Barking, Havering and Redbridge University Hospitals NHS Trust) are fine to use, but avoid jargon or overly complex terms unless necessary. Clear and straightforward language generally yields better responses.</li>
                    </ul>
                    </div>

                    <div className="py-2">
                        <h4 className="text-3572EF mb-4 mt-3">Examples of Well-Structured Questions </h4>
                        <h6 className="px-4">1. What are the key digital strategies for NHS Trusts in London for the upcoming fiscal year?</h6>
                            <ul className="pb-2 mx-4">
                                <li>Why it's good: This query specifies the geographic area (London) and the time frame (upcoming fiscal year), making it more targeted.</li>
                            </ul>

                        <h6 className="px-4">2. How is Portsmouth Hospitals NHS Trust leveraging AI technologies?</h6>
                            <ul className="pb-2 mx-4">
                                <li>Why it's good: This query is specific, mentions a particular trust, and focuses on a clear topic (AI technologies). </li>
                            </ul>

                        <h6 className="px-4">3. What cybersecurity measures are being implemented at SATH to protect the digital systems from potential threats? </h6>
                            <ul className="pb-2 mx-4">
                                <li>Why it's good: This question is detailed, mentioning a specific trust (Shrewsbury and Telford) and focusing on a specific area (cybersecurity measures), making it precise and relevant.</li>
                            </ul>
                   </div>


                   <div className="py-2">
                        <h4 className="text-3572EF mb-4 mt-3">Examples of Poorly Framed Questions </h4>
                        <h6 className="px-4">1. What does the NHS do with technology?</h6>
                            <ul className="pb-2 mx-4">
                                <li>Why it needs improvement: This question is too vague and broad. It lacks specificity about what aspect of technology or which NHS organisations are being referred to.</li>
                            </ul>

                        <h6 className="px-4">2. Tell me about digital improvements in the NHS.</h6>
                            <ul className="pb-2 mx-4">
                                <li>Why it needs improvement: This is a general query that does not specify which improvements, which NHS Trusts, or any particular timeframe.</li>
                            </ul>

                        <h6 className="px-4">3. What is the NHS's strategy?</h6>
                            <ul className="pb-2 mx-4">
                                <li>Why it needs improvement: It’s too generic and does not specify whether it’s referring to digital, financial, operational, or another type of strategy.</li>
                            </ul>
                   </div>
            </div>
            </>
    );
};

export default UserGuide;