import React from 'react';

const FeedBack = () => {
    return (
        <div className="container">
            <h4 className="text-3572EF mb-4 mt-2">Feedback</h4>
            {/* Embedded SurveyMonkey form using an iframe */}
            <div className='w-100 m-auto'>
                <iframe
                    src="https://www.surveymonkey.com/r/J976QG6"
                    width="100%"
                    height="1400"
                    title="SurveyMonkey Feedback Form"
                ></iframe>
            </div>
        </div>
    );
};

export default FeedBack;
