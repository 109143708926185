import React from "react";
import AnimatedText from "components/AnimatedText";
import { useSettings } from "contexts/SettingsContext";

const AuthAnimation = () => {
    const { settings, isLoading } = useSettings();

    if (isLoading) {
        return <div className="text-center">Loading animation texts...</div>;
    }

    const textItems = settings.login_animation_texts || [];

    return (
        <>
            {textItems.length > 0 ? (
                <>
                    <span className="mask bg-gradient-dark opacity-6" style={{
                        backgroundImage:
                            "url(" + require("../../assets/img/theme/Group-4-1.svg").default + ")",
                        backgroundSize: "cover", backgroundPosition: "left",
                    }}></span>
                    <div className="top-50 position-absolute mt-n6 px-4">
                        <AnimatedText texts={textItems} />
                    </div>
                </>
            ) : (
                <div>No animation texts available</div>
            )}
        </>
    );
};

export default AuthAnimation;
