// import logoimg from '../assets/img/bevanai-mainlogo.png';

// const Logo = (props) => {
//     return (
//         <div className="w-100 mb-2 text-center">
//             <img src={logoimg} className="w-70" alt="" /> <span className="align-bottom fs-6">(beta)</span>
//         </div>
//     );
// };

// export default Logo;



import React from 'react';
import { imageAssets } from './imageAssets'; // Correctly import imglogo from logoimages.js

const Logo = ({ mainlogo = imageAssets.mainlogo }) => {
    return (
        <div className="w-100 mb-2 text-center">
            <img src={mainlogo} className="w-70" alt="BevanAI Main Logo" /> 
            <span className="align-bottom fs-6"> (beta)</span>
        </div>
    );
};

export default Logo;




