
export const imageAssets = {
    mainlogo: require('../assets/img/bevanai-mainlogo.png'),
    blacklogo: require('../assets/img/bevanai-logo.png'),
    bluelogoicon: require('../assets/img/bevan-icon.png'),
    step1: require('../assets/img/step-1.png'),
    step2: require('../assets/img/step-2.png'),
    step3: require('../assets/img/step-3.png'),
    step4: require('../assets/img/step-4.png'),
};

