import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";

const FooterInnerNav = (props) => {
    return (
        
        <Container>
          <Row className="align-items-center justify-content-xl-between">
            <Col xl="12">
              <Nav className="nav-footer justify-content-center">
                <NavItem className="text-sm text-3572EF">
                  <NavLink href="/faq" target="_blank">
                    FAQ
                  </NavLink>
                </NavItem>
                <NavItem className="text-sm text-3572EF">
                  <NavLink href="/user-guide" target="_blank">
                    User Guide
                  </NavLink>
                </NavItem>
                <NavItem className="text-sm text-3572EF">
                  <NavLink href="/feedback" target="_blank">
                    Feedback
                  </NavLink>
                </NavItem>                
              </Nav>
            </Col>
          </Row>
          </Container>
    );
};

export default FooterInnerNav;