

const Faq = () => {
    return (
        <>
            <div className="container">
            <h4 className="text-dark mb-4 mt-3">Bevan.ai Introduction & FAQs </h4>

            <div className="pb-2"> 
                <h5 className="text-3572EF"><i class="fa-solid fa-angles-right text-md"></i> What is Bevan.ai? </h5>
                    <p className="text-dark px-4">Named after Nye Bevan, the politician most associated with founding the NHS, Bevan.ai is a new large language AI model specifically developed to help users better understand the complexity of today’s highly fragmented NHS. </p>
                    <p className="text-dark px-4">In its first phase, Bevan.ai is being trained to provide users with insights into policies, plans, and strategies concerning the current and future use of digital and data across the NHS. Bevan.ai aims to help users identify trends, accelerate the adoption of best practices, and spot market opportunities.</p>
            </div>

            <div className="pb-2"> 
                <h5 className="text-3572EF"><i class="fa-solid fa-angles-right text-md"></i> Who has Bevan.ai been developed by? </h5>
                    <p className="text-dark px-4">Bevan.ai has been developed by Digital Health Intelligence, a specialist B2B data, events, and news publisher. </p>
                    <p className="text-dark px-4">For the past decade, Digital Health Intelligence has provided the most detailed data on installed systems and infrastructure across every NHS organisation. With Bevan.ai, we are extending our expertise to offer insights into the policies, strategies, and plans that drive those investments. </p>
             </div>

             <div className="pb-2"> 
                <h5 className="text-3572EF"><i class="fa-solid fa-angles-right text-md"></i> What dataset is Bevan.ai trained against?</h5>
                    <p className="text-dark px-4">Bevan.ai is currently trained on a dataset of 3,000 documents, including NHS Trust and Integrated Care Board (ICB) annual plans, digital strategies, quality accounts, integrated care strategies, joint forward plans, joint capital resource plans, and select board papers. These documents have been categorised and tagged with metadata to enhance their usability.</p>
                    <p className="text-dark px-4">In the next phase of the project, Bevan.ai will ingest and index board papers from trusts and ICBs as they are published. </p>
            </div>

            <div className="pb-2"> 
                <h5 className="text-3572EF"><i class="fa-solid fa-angles-right text-md"></i> How are responses generated? </h5>
                    <p className="text-dark px-4">When a user submits a query, Bevan.ai first queries its bespoke LLM model. If the model cannot generate a satisfactory response, the platform will then query the public ChatGPT model. These different responses are clearly distinguished.</p>
            </div>

            <div className="pb-2"> 
                <h5 className="text-3572EF"><i class="fa-solid fa-angles-right text-md"></i> What LLM is being used? </h5>
                    <p className="text-dark px-4">Bevan.ai has been trained on a dedicated OpenAI GPT-3.5 model, developed in collaboration with Digital Health Intelligence.</p>
                    <p className="text-dark px-4">Given the rapid advancements in large language models, we are also exploring the use of open-source models such as Llama for future iterations. </p>
            </div>

            <div className="pb-2"> 
                <h5 className="text-3572EF"><i class="fa-solid fa-angles-right text-md"></i> What problem is Bevan.ai trying to address?</h5>
                    <p className="text-dark px-4">Bevan.ai has been trained on a dedicated OpenAI GPT-3.5 model, developed in collaboration with Digital Health Intelligence.</p>
                    <ul className="mx-4">
                        <li><strong>Fragmentation and Complexity:</strong> The NHS’s complexity and fragmentation make it challenging to understand technology adoption across the system.</li>
                        <li><strong>Demand for Answers: </strong>System-Wide Insights: It’s difficult for suppliers, policymakers, analysts, and operational managers to obtain a comprehensive view of technology adoption and strategies that are effective and improve productivity.</li> 
                        <li><strong>System-Wide Insights:</strong> It’s difficult for suppliers, policymakers, analysts, and operational managers to obtain a comprehensive view of technology adoption and strategies that are effective and improve productivity.</li>
                        <li><strong>Locked Data:</strong> Valuable operational, policy, and strategy data is often trapped in unstructured PDFs within individual organisations.</li> 
                        <li><strong>Broader Insights:</strong> Bevan.ai aims to provide insights not just from individual organisations but from across the entire NHS system. </li>
                    </ul>
            </div>

            <div className="pb-2"> 
                <h5 className="text-3572EF"><i class="fa-solid fa-angles-right text-md"></i> How does Bevan.ai handle inaccuracies or outdated information? </h5>
                    <p className="text-dark px-4">Bevan.ai is still in its beta phase, and while it strives to provide accurate information, there may be instances where responses are incomplete or outdated. We encourage users to provide feedback through the thumbs-up/down rating system, which helps us improve the model's accuracy and relevance over time.</p>
            </div>

            <div className="pb-2"> 
                <h5 className="text-3572EF"><i class="fa-solid fa-angles-right text-md"></i> What are the limitations of Bevan.ai in its current phase? </h5>
                    <p className="text-dark px-4">As Bevan.ai is still in its beta phase, there are still some serious limitations. For example, answers may be partial or incorrect. The model may not always provide exhaustive answers, especially for complex queries, and it relies on the existing dataset, which is continuously expanding. We recommend refining your questions or trying different queries to get the best results.</p>                        
            </div>

            <div className="pb-2"> 
                <h5 className="text-3572EF"><i class="fa-solid fa-angles-right text-md"></i> Use of Bevan - disclaimer </h5>
                    <p className="text-dark px-4">Bevan.ai is a tool under development and users should be cautious of responses, which can be partial or incorrect, they should not act on those responses without independently verifying them. Digital Health Intelligence provides no warranty for the accuracy of information provided by Bevan.ai.</p> 
            </div>

            <div className="pb-2"> 
                <h5 className="text-3572EF"><i class="fa-solid fa-angles-right text-md"></i> Is my data secure when using Bevan.ai? </h5>
                    <p className="text-dark px-4">Yes, your data is secure. All interactions with Bevan.ai, including queries and responses, are confidential and stored securely. We do not sell or share your data with third parties. Aggregated usage data may be used to help improve and refine the model, but individual user data remains private. </p> 
            </div>

            <div className="pb-2"> 
                <h5 className="text-3572EF"><i class="fa-solid fa-angles-right text-md"></i> How can I provide feedback, suggest features, or get support with Bevan.ai? </h5>
                    <p className="text-dark px-4">For detailed feedback, feature suggestions, or support with any issues or questions, please contact our team at <a href="mailto:Team@bevan.ai" className="text-primary">Team@bevan.ai</a>. Your input is invaluable in shaping Bevan.ai and ensuring you get the most out of the platform. </p>   
                            </div>
            </div>           

        </>
    );
};

export default Faq;