import React, { useState } from 'react';
import { Button } from 'reactstrap';

import { messageFeedback } from 'network/ApiAxios';

const Feedback = ({ isVisible, isLastMessage, messageId, parentId, sessionId, regenerateResponse }) => {
    const token = localStorage.getItem('token');
    const [hoverState, setHoverState] = useState({
        regenerate: false,
        yes: false,
        partial: false,
        no: false
    });
    const [feedbackResponse, setFeedbackResponse] = useState({
        message: '',
        isVisible: false
    });


    const handleMouseEnter = (button) => {
        setHoverState(prevState => ({ ...prevState, [button]: true }));
    };

    const handleMouseLeave = (button) => {
        setHoverState(prevState => ({ ...prevState, [button]: false }));
    };

    const handleFeedback = async (rating) => {
        const feedbackData = {
            message_id: messageId,
            session_id: sessionId,
            rating: rating
        };

        try {
            await messageFeedback(token, feedbackData);
            setFeedbackResponse({
                message: 'Thanks for your feedback!',
                isVisible: true
            });
            setTimeout(() => {
                setFeedbackResponse(prevState => ({
                    ...prevState,
                    isVisible: false
                }));
            }, 3000);
        } catch (error) {
            console.error('Feedback submission failed:', error);
        }
    };


    const regenerateMessage = (messageId, parentId) => {
        const userMessages = document.querySelectorAll('div[data-message-author-role="user"]');
        const lastUserMessage = userMessages[userMessages.length - 1];
        const query = lastUserMessage ? lastUserMessage.textContent.trim() : '';

        if (query && messageId && parentId) {
            regenerateResponse('', query, messageId, parentId);
        } else {
            console.error(`No message found for regeneration. Missing query: ${query ? 'No' : 'Yes'}, messageId: ${messageId ? 'No' : 'Yes'}, parentId: ${parentId ? 'No' : 'Yes'}`);
        }
    };

    return (
        <>
            <div className="feedback-container mt-1 position-relative" >
                <div className={`text-gray-400 flex self-end lg:self-center items-center justify-center lg:justify-start mt-0 -ml-1 h-7`}>  {/* ${isVisible ? 'visible' : 'invisible'} */}
                    {isLastMessage && (
                        <span className="regenerate" data-state="closed">
                            <Button
                                className={`flex items-center rounded-md m-0 p-1 text-xs  border-0 bg-white shadow-none ${hoverState.regenerate ? 'text-dark' : 'text-tertiary'}`}
                                onMouseOver={() => handleMouseEnter('regenerate')}
                                onMouseOut={() => handleMouseLeave('regenerate')}
                                onClick={() => regenerateMessage(messageId, parentId)}
                            >
                                <div className="flex items-center gap-1.5 text-xs">
                                    <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="me-1"><path fillRule="evenodd" clipRule="evenodd" d="M4.5 2.5C5.05228 2.5 5.5 2.94772 5.5 3.5V5.07196C7.19872 3.47759 9.48483 2.5 12 2.5C17.2467 2.5 21.5 6.75329 21.5 12C21.5 17.2467 17.2467 21.5 12 21.5C7.1307 21.5 3.11828 17.8375 2.565 13.1164C2.50071 12.5679 2.89327 12.0711 3.4418 12.0068C3.99033 11.9425 4.48712 12.3351 4.5514 12.8836C4.98798 16.6089 8.15708 19.5 12 19.5C16.1421 19.5 19.5 16.1421 19.5 12C19.5 7.85786 16.1421 4.5 12 4.5C9.7796 4.5 7.7836 5.46469 6.40954 7H9C9.55228 7 10 7.44772 10 8C10 8.55228 9.55228 9 9 9H4.5C3.96064 9 3.52101 8.57299 3.50073 8.03859C3.49983 8.01771 3.49958 7.99677 3.5 7.9758V3.5C3.5 2.94772 3.94771 2.5 4.5 2.5Z" fill="currentColor"></path></svg>
                                </div>
                            </Button>
                        </span>
                    )}
                    <span className="yes" data-state="closed">
                        <Button
                            className={`flex items-center rounded-md m-0 p-1 text-xs shadow-none ${hoverState.yes ? 'text-dark' : 'text-tertiary'} hover:text-primary border-0 bg-white`}
                            onMouseOver={() => handleMouseEnter('yes')}
                            onMouseOut={() => handleMouseLeave('yes')}
                            onClick={() => handleFeedback('yes')}
                        >
                            <div className="flex items-center text-xs">
                                <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="me-1 flex-shrink-0" height="18" width="18" xmlns="http://www.w3.org/2000/svg"><path d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3"></path></svg>
                            </div>
                        </Button>
                    </span>
                    <span className="partial" data-state="closed">
                        <Button
                            className={`flex items-center rounded-md m-0 p-1 text-xs shadow-none ${hoverState.partial ? 'text-dark' : 'text-tertiary'} hover:text-primary border-0 bg-white`}
                            onMouseOver={() => handleMouseEnter('partial')}
                            onMouseOut={() => handleMouseLeave('partial')}
                            onClick={() => handleFeedback('partial')}
                        >
                            <div className="flex items-center text-xs">
                                <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="me-1 flex-shrink-0 rounded border border-secondary" height="18" width="18" xmlns="http://www.w3.org/2000/svg"><desc></desc><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M5 9h14m-14 6h14"></path></svg>
                            </div>
                        </Button>
                    </span>
                    <span id="RegenerateTooltip" className="no" data-state="closed">
                        <Button
                            className={`flex items-center rounded-md m-0 p-1 text-xs shadow-none ${hoverState.no ? 'text-dark' : 'text-tertiary'} hover:text-primary border-0 bg-white`}
                            onMouseOver={() => handleMouseEnter('no')}
                            onMouseOut={() => handleMouseLeave('no')}
                            onClick={() => handleFeedback('no')}
                        >
                            <div className="flex items-center">
                                <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="me-1 flex-shrink-0" height="18" width="18" xmlns="http://www.w3.org/2000/svg"><path d="M10 15v4a3 3 0 0 0 3 3l4-9V2H5.72a2 2 0 0 0-2 1.7l-1.38 9a2 2 0 0 0 2 2.3zm7-13h2.67A2.31 2.31 0 0 1 22 4v7a2.31 2.31 0 0 1-2.33 2H17"></path></svg>
                            </div>
                        </Button>
                    </span>
                </div>
                {feedbackResponse.isVisible && (
                    <div className="text-center mt-2 w-100">
                        <div className="bg-light inline-flex rounded shadow-sm px-4 py-2 mx-auto position-absolute top-0 start-0 end-0 w-25">
                            <div className="text-sm text-dark">{feedbackResponse.message}</div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default Feedback;
