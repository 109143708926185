
const PrivacyPolicy = () => {
    return (
        <>
            <div className="container">
                <h4 className="text-3572EF mt-2 mb-3">Privacy policy</h4>
                    <p className="text-dark">Digital Health Intelligence, the intelligence, data and research business of Digital Health, are committed to protecting and respecting your data and privacy.</p>

                    <p className="text-dark">This policy (and any other documents referred to on it) sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us. Please read the following carefully to understand our views and practices regarding your personal data and how we will treat it.</p>

                    <p className="text-dark">If you have any questions about this policy and our privacy practices, please email <a href="mailto:datacontroller@digitalhealth.net" className="text-primary">datacontroller@digitalhealth.net</a> or write to The Data Controller, Kemp House, 152-160 City Road, London, EC1V 2NX. Alternatively, you can call us on 020 7785 6900.</p>
                
                    <p className="text-dark">The data controller is Digital Health Intelligence Limited of Kemp House, 152-160 City Road, London, EC1V 2NX.</p >

                    <p className="text-dark">Our nominated representative for the purpose of Data Protection is Katerina Loucka.</p>

                    <div className="pb-2">    
                        <h5 className="text-3572EF"><i class="fa-solid fa-angles-right text-md"></i> Who are we? </h5>

                            <p className="text-dark px-4">Digital Health Intelligence provides data, research and analysis focused on the UK health IT and digital health sectors.</p>

                            <p className="text-dark px-4">The Bevan.ai service is an AI platform developed by Digital Health Intelligence to provide insight into the current and future plans, policies, strategies, operation and best practices of NHS organisations in the use of digital and data.</p>
                    </div>

                    <div className="pb-2"> 
                        <h5 className="text-3572EF"><i class="fa-solid fa-angles-right text-md"></i> Use of Bevan - disclaimer</h5>

                            <p className="text-dark px-4">Bevan.ai is a tool under development and users should be cautious of responses, which can be partial or incorrect, they should not act on those responses without independently verifying them.</p>

                            <p className="text-dark px-4">Digital Health Intelligence provides no warranty for the accuracy of information provided by Bevan.ai</p>
                   </div>

                   <div className="pb-2"> 
                        <h5 className="text-3572EF"><i class="fa-solid fa-angles-right text-md"></i> Information we may collect about you</h5>
                        <p className="text-dark px-4">We may collect and process the following data about you:</p>

                        <h6 className="px-4">Registration data</h6>
                            <ul class="mx-4">
                                <li>Log-in, session and usage analytics</li>
                                <li>Questions, queries and searches – questions, queries and searches caried out on the Bevan.ai platform</li>
                                <li>Professional Information – data that customers provide to Digital Health is considered professional information that is stored and managed for Digital Health’s internal use. Professional information includes, but is not limited to, name, address, company name, company address, phone number email address, as well as questions answered on survey or registration forms.</li>
                            </ul>  
                    </div>

                    <div className="pb-2"> 
                        <h5 className="text-3572EF"><i class="fa-solid fa-angles-right text-md"></i> IP addresses</h5>
                            <p className="text-dark px-4">We may collect information about your computer, including where available your IP address, operating system and browser type, for system administration and to report aggregate information to our advertisers. This is statistical data about our users’ browsing actions and patterns, and does not identify any individual.</p>
                    </div>

                    <div className="pb-2"> 
                        <h5 className="text-3572EF"><i class="fa-solid fa-angles-right text-md"></i> Sale and sharing of your data</h5>
                            <p className="text-dark px-4">Digital Health Intelligence undertakes not to sell or share your data with thord parties without explicit advance consent</p>                    
                  </div>

                  <div className="pb-2"> 
                    <h5 className="text-3572EF"><i class="fa-solid fa-angles-right text-md"></i> Where we store your information</h5>
                        <p className="text-dark px-4">Your personal data is stored within our database within the UK in a Salesforce CRM system and on our intelligence and Bevan platform. Your personal preferences and the newsletters or notifications that you are subscribed to are recorded in these systems to ensure that you receive the right information from Digital Health.</p>
                  </div>      

                  <div className="pb-2"> 
                    <h5 className="text-3572EF"><i class="fa-solid fa-angles-right text-md"></i>  How we use your information</h5>
                        <p className="text-dark px-4">We use information held about you in the following ways:</p>

                        <ul className="mx-4">
                            <li>To train the Bevan.ai LLM.  The use of the Bevan.ai service forms an important part of the training of the model.  Digital Health Intelligence will also analyse questions and user analytics data generated.</li> 

                            <li>ensure that content from our site is presented in the most effective manner for you and for your computer.</li>

                            <li>To provide you with information, products or services that you request from us or which we feel may interest you.</li>

                            <li>To carry out our obligations arising from any contracts entered into between you and us.
                            To allow you to participate in interactive features of our service, when you choose to do so.</li>
                        </ul>
                </div>

                <div className="pb-2"> 
                    <h5 className="text-3572EF"><i class="fa-solid fa-angles-right text-md"></i> To notify you about changes to our service.</h5>
                        <p className="text-dark px-4">If you are an existing customer, we will only contact you by electronic means (e-mail or SMS) with information about goods and services similar to those which were the subject of a previous sale to you. If you do not want us to use your data in this way, you can unsubscribe to any emails by using the unsubscribe feature at the bottom of all of our emails.</p>
                 </div>

                <div className="pb-2"> 
                    <h5 className="text-3572EF"><i class="fa-solid fa-angles-right text-md"></i> How we protect your data</h5>
                        <p className="text-dark px-4">We have put in place security procedures and technical and organisational measures to safeguard your personal information. Access to all internal data servers is limited to the specialist data personnel and is controlled via database structure authentication using defined policies to control password expiry and renewal.</p>
                </div>

                <div className="pb-2"> 
                    <h5 className="text-3572EF"><i class="fa-solid fa-angles-right text-md"></i> Your rights</h5>
                    <h6 className="px-4">Legal basis for collecting, storing and processing personal data</h6>
                        <p className="text-dark px-4">Digital health uses several legal basis for collecting, sorting and using your personal information. Much of our data will be opted in use for the legal basis, but we also utilise legitimate interest as a legal basis for the use and processing of personal information. For legitimate interest we have and will test to make sure we feel it is necessary to use under legitimate interest and therefore opt in is not required. You can read more about legitimate interest <a href="https://ico.org.uk/for-organisations/uk-gdpr-guidance-and-resources/lawful-basis/a-guide-to-lawful-basis/lawful-basis-for-processing/legitimate-interests/" target="_blank" rel="noreferrer" className="text-primary">here</a>.</p>

                        <p className="text-dark px-4">You have the right to ask us not to process your personal data for marketing purposes. You can exercise your right to prevent such processing by ticking certain boxes on the forms we use to collect your data. You can also exercise the right at any time by contacting us at <a href="mailto:datacontroller@digitalhealth.net" className="text-primary">datacontroller@digitalhealth.net</a>.</p>

                        <p className="text-dark px-4">Our site may, from time to time, contain links to and from the websites of our partner networks, advertisers and affiliates. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for these policies. Please check these policies before you submit any personal data to these websites.</p>
                </div>

                <div className="pb-2"> 
                    <h5 className="text-3572EF"><i class="fa-solid fa-angles-right text-md"></i> Removing your consent</h5> 
                        <p className="text-dark px-4">By submitting your personal information you consent to the use of that information as set out in this policy. You may withdraw your consent at any time by writing to us either by emailing <a href="mailto:datacontroller@digitalhealth.net" className="text-primary">datacontroller@digitalhealth.net</a> or by post to The Data Controller, Digital Health Intelligence Limited of Kemp House, 152-160 City Road, London, EC1V 2NX.</p>
                </div>

                <div className="pb-2"> 
                    <h5 className="text-3572EF"><i class="fa-solid fa-angles-right text-md"></i> Access to your information</h5>
                        <p className="text-dark px-4">You have the right to request a copy of the information that we hold about you. If you would like a copy of some or all of your personal information, please send an email to <a href="mailto:datacontroller@digitalhealth.net" className="text-primary">datacontroller@digitalhealth.net</a>.</p>

                        <p className="text-dark px-4">We want to ensure that your personal information is accurate and up to date. If any of the information that you have provided to us changes, for example if you change your email address or name, please let us know the correct details by email to <a href="mailto:datacontroller@digitalhealth.net" className="text-primary">datacontroller@digitalhealth.net</a>. You may ask us, or we may ask you, to correct information you or we think is inaccurate, and you may also ask us to remove information which is inaccurate.</p>
                </div>

                <div className="pb-2"> 
                <h5 className="text-3572EF"><i class="fa-solid fa-angles-right text-md"></i> Changes to our Privacy Policy</h5>
                <p className="text-dark px-4">Any changes we may make to our privacy policy in the future will be posted on this page and, where appropriate, notified to you by email. This policy was last reviewed in August 2024.</p>

                <h5 className="text-3572EF"><i class="fa-solid fa-angles-right text-md"></i> Contact us</h5>
                <p className="text-dark px-4">Questions, comments and requests regarding this privacy policy are welcomed and should be addressed to <a href="mailto:datacontroller@digitalhealth.net" className="text-primary">datacontroller@digitalhealth.net</a>.</p>
                </div>
            </div>
        </>
    );
};

export default PrivacyPolicy;