import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from "react-router-dom";

import { PropTypes } from "prop-types";

// reactstrap components
import {
  Collapse,
  Navbar,
  Container,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

import Logo from "components/Logo";
import Copyright from 'components/Footers/Copyright';
import { allChatHistories } from "network/ApiAxios";
import { useChatContext } from 'contexts/ChatContext';
import ShowByDate from './ShowByDate';
import { useSidebar } from "contexts/SidebarContext";
import SettingsModal from 'views/modals/SettingsModal';

const Sidebar = (props) => {
  const { isOpen, toggleSidebar } = useSidebar();

  const { conversationsUpdated, resetConversationsUpdate } = useChatContext();
  const userFirstName = localStorage.getItem('user_firstname');
  const userLastName = localStorage.getItem('user_lastname');
  const [items, setItems] = useState([]);
  const [settingsModal, setsettingsModal] = useState(false);

  const navigate = useNavigate();
  const fetchItems = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await allChatHistories(token);
      if (response) {
        setItems(response.items);
      }
    } catch (error) {
      console.error('Failed to fetch chat histories:', error);
      setItems([]);
    }
  }, []);

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  useEffect(() => {
    if (conversationsUpdated) {
      fetchItems();
      resetConversationsUpdate();
    }
  }, [conversationsUpdated, resetConversationsUpdate, fetchItems]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user_firstname');
    localStorage.removeItem('user_lastname');

    navigate('/auth/login');
  };
  const toggleModal = () => {
    setsettingsModal(!settingsModal);
  };

  return (
    <>
      <Navbar
        className={`sidenav bg-gray-100 navbar navbar-vertical navbar-expand-xs border-0 fixed-start px-3 ${isOpen ? 'd-block' : 'd-none d-lg-block'}`}
        id="sidenav-main"
        style={{ zIndex: 0 }}
      >
        <div className="sticky-top bg-gray-100 py-3">
          <div className='d-flex align-items-center'>
            <div className="pe-2">
              <a href="/c/" className="" style={{ textDecoration: 'none' }}>
                <Logo />
              </a>
            </div>
            
            <div className="">
              {isOpen ? (
                <button
                  className="btn p-1 m-0 shadow-none d-lg-none border-0 bg-none text-lg"
                  onClick={(e) => {
                    e.preventDefault();
                    toggleSidebar();
                  }}
                >
                  <i className="fa-solid fa-xmark text-dark" />
                </button>
              )
                : (
                  <a href="/c/" className="" style={{ textDecoration: 'none' }}>
                    <i className="fa-solid fa-pen-to-square text-primary" />
                  </a>
                )
              }
            </div>
          </div>
          <hr className="horizontal dark" />
        </div>
        <Container fluid>

          {/* Collapse */}
          <Collapse navbar className='w-100' id="sidenav-collapse-main">
            {/* Navigation */}
            <ShowByDate items={items} />
          </Collapse>
        </Container>

        <div className="bg-gray-100 sticky-bottom py-1 px-3 text-center">
          <UncontrolledDropdown className="dropup">
            <DropdownToggle className='bg-gray-100 text-dark shadow-none text-center py-2 px-0 mb-0'>
              <i className="fa-solid fa-circle-user"></i>&nbsp;
              {userFirstName} {userLastName}
            </DropdownToggle>
            <DropdownMenu className='w-100 shadow-sm p-2'>
              <a href="#settings" onClick={(e) => { e.preventDefault(); toggleModal(); }} className="nav-link font-weight-bold p-2 d-block">
                <i className="fa-solid fa-gear me-sm-1"></i>
                <span className="d-sm-inline">Settings</span>
              </a>
              <hr className="horizontal dark my-2" />
              <a href="#dhi" onClick={(e) => { e.preventDefault(); handleLogout(); }} className="nav-linkfont-weight-bold d-block p-2">
                <i className="fa fa-user me-sm-1" aria-hidden="true"></i>
                <span className="d-sm-inline d-none">Logout</span>
              </a>
            </DropdownMenu>
          </UncontrolledDropdown>

          <Copyright />
        </div>
      </Navbar >
      <SettingsModal isOpen={settingsModal} toggle={toggleModal} />
    </>

  );
};

Sidebar.defaultProps = {
  routes: [
  ],
};

Sidebar.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    innerLink: PropTypes.string,
    outterLink: PropTypes.string,
    imgSrc: PropTypes.string.isRequired,
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;
