import React, { createContext, useContext, useState } from 'react';

const ChatContext = createContext();

export const useChatContext = () => useContext(ChatContext);

export const ChatProvider = ({ children }) => {
    const [conversationsUpdated, setConversationsUpdated] = useState(false);

    const refreshConversations = () => {
        setConversationsUpdated(prev => !prev);
    };
    const resetConversationsUpdate = () => {
        setConversationsUpdated(false);
    };

    return (
        <ChatContext.Provider value={{ conversationsUpdated, refreshConversations, resetConversationsUpdate }}>
            {children}
        </ChatContext.Provider>
    );
};
