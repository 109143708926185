// import aboutimg from 'assets/img/section4-img4.png';


const AboutUs = () => {
    return (
        <>
            <div className="container">
                <h4 className="text-3572EF mb-3 mt-2">About Us</h4>
                <p className="text-dark">Bevan.ai (Beta) is a dedicated new LLM model being developed to help NHS leaders and suppliers gain intelligent insights into current and future use of digital and data across the complex patchwork of the NHS in England. </p>
                <p className="text-dark">Currently, much of this information is locked in unstructured board papers, usually PDFs, making it hard to find and extract meaning from, identify what organisations are using what technologies, what their plans are and to identify best practices. </p>
                <p className="text-dark">Bevan.ai is being developed and trained to enable users to gain insights faster and more easily by asking Bevan anything from digital adoption market trends to specific questions about an NHS trusts’ current digital requirements.</p>
                <p className="text-dark">Bevan.ai has initially been trained against a mass of 3,000 local NHS trust and ICB plans, strategies and board papers, which have been tagged with meta data and loaded into a dedicated OpenAI ChatGPT 3.5 model (other models may also be used in the future).</p>
                <p className="text-dark">In the next phase of development, NHS trust and board papers will be automatically added as they are published, ensuring that over time Bevan becomes the most comprehensive and up-to-date source of data on digital plans and strategies across the NHS.</p>
                <p className="text-dark">We will also work with partners to explore what additional data sources could be usefully added to Bevan to further develop the model and its value to users</p>
                <p className="text-dark">During the Beta phase Bevan will be independently evaluated.</p>
                <p className="text-dark">Launched in Beta in September 2024 access is by invitation or application, initially offered to local NHS digital leaders from Digital Health Networks, the community of 7,000 NHS leaders, as well as to current NHS Intelligence subscribers.</p>
                <p className="text-dark">Bevan is being developed by Digital Health the specialist B2B news, events, and intelligence publisher dedicated to providing insight into the digital future of health and care.</p>

            </div>

  



        </>
    );
};

export default AboutUs;