import React from "react";
import styled from "styled-components";
import { keyframes } from "styled-components";

export default function AnimatedGradientText({ text }) {
  return (
    <>
      <GradientText>{text}</GradientText>
    </>
  );
}

const slide = keyframes`
  0% {
    background-position: 200%;
  }
  100% {
    background-position: 0%;
  }
`;
const GradientText = styled.span`
  background-image: linear-gradient(
    90deg,
    #212529 0%,
    #b5b5b5 50%,
    #212529 100%
  );
  background-size: 200% auto;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  animation: ${slide} 3s infinite linear;
  font-feature-settings: "kern";
  overflow-wrap: break-word;
  text-align: center;
  text-rendering: optimizelegibility;
  -moz-osx-font-smoothing: grayscale;
`;